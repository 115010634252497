import Button from 'components/atoms/Button/Button';
import Image from 'components/atoms/Image/Image';
import React, { FC, useEffect, useState } from 'react';
import uniqid from 'uniqid';
import Carousel from '../../components/Carousel/Carousel';
import { FeatureListItem, PricingCardData } from './pricingCardsData';
import { PricingSectionProps } from './PricingSection.model';

const PricingSection: FC<PricingSectionProps> = ({
  title,
  featureLists,

  requestQuoteUrl: requestoQuoteUrl,
  targetBlank = true,
  expandCloseCards,
}) => {
  const [seeAll, setSeeAll] = useState<boolean>(false);
  const [lists, setLists] = useState<PricingCardData>(featureLists);

  useEffect(() => {
    if (seeAll) {
      setLists(featureLists);
    } else {
      const shortLists: PricingCardData = featureLists?.map((list) => [list[0]]);
      setLists(shortLists);
    }
  }, [seeAll]);

  const carouselSettings = {
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          arrows: false,
          infinite: false,
        },
      },
    ],
  };

  return (
    <section id="pricing_cards" className="pricing-cards mb-section scroll-smooth ">
      <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text text-center mb-8">
        {title}
      </h2>

      <div className="md:w-full md:flex md:justify-center">
        <div className="md:max-w-[80rem]">
          <Carousel className="pricing-slider" settings={carouselSettings} autoplaySpeed={0}>
            {lists?.map((list: FeatureListItem[], index: number) => (
              <div key={index}>
                <div
                  className={`md:max-w-[23rem] p-l rounded-3xl mx-l mb-xl shadow-15 tablet:max-w-sm tablet:mx-auto ${
                    index === 1 ? 'border border-primary' : ''
                  }`}
                >
                  {list.map((item: FeatureListItem) => (
                    <>
                      <div className="mb-m">
                        <h3 className="mb-none">{item?.cardInfo?.titleCard}</h3>
                        <span className="text-xs text-base-300">
                          {item?.cardInfo?.subtitleCard}
                        </span>
                      </div>

                      <div
                        className={`mb-4 ${
                          item?.cardInfo?.customClassPricing
                            ? `${item.cardInfo?.customClassPricing} md:mb-24`
                            : 'md:mb-xxl'
                        }`}
                      >
                        <h1 className="mb-none">{item.cardInfo?.pricing}</h1>
                        {item.cardInfo?.subTitlePricing}
                      </div>
                    </>
                  ))}

                  <div className="mb-xl">
                    <FeatureList list={list} />
                  </div>
                  <a
                    href={
                      index !== 2
                        ? 'https://fractal.cloud/register'
                        : requestoQuoteUrl || '/contact-us'
                    }
                    {...(index !== 2
                      ? { target: '_blank' }
                      : targetBlank
                      ? { target: '_blank' }
                      : {})}
                    rel="noopener noreferrer"
                  >
                    <Button
                      kind={index === 1 ? 'Primary' : 'Secondary'}
                      className="w-full text-nowrap py-2"
                    >
                      {list.map((item) => (
                        <span className="flex items-center gap-3">{item.cardInfo?.ctaLabel}</span>
                      ))}
                    </Button>
                  </a>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      <div className="flex justify-center">
        {seeAll ? (
          <a
            className="text-blue-900 flex items-center mb-none"
            onClick={() => setSeeAll(!seeAll)}
            href="#pricing_cards"
            aria-label={expandCloseCards?.closeCardsLabel}
          >
            <span className="mr-xxs">{expandCloseCards?.closeCardsLabel}</span>
            <Image name="chevron-left" className="w-s -rotate-90" />
          </a>
        ) : (
          <button
            className="text-blue-900 flex items-center"
            onClick={() => setSeeAll(!seeAll)}
            aria-label={expandCloseCards?.expandCardsLabel}
          >
            <span className="mr-xxs">{expandCloseCards?.expandCardsLabel}</span>
            <Image name="chevron-left" className="w-s rotate-90" />
          </button>
        )}
      </div>
    </section>
  );
};

const FeatureList: React.FC<{ list: FeatureListItem[] }> = ({ list }) => {
  if (!list || list.length === 0) {
    return null;
  }
  return (
    <>
      {list.map((subList, i) => (
        <div className="mb-xxl" key={uniqid('pricing-cards-')}>
          {subList.header && <div className="font-bold mb-m">{subList.header}</div>}
          <ul>
            {subList.content.map((item, j) => (
              <FeatureRow key={j} item={item} />
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

const FeatureRow: React.FC<{ item: { [key: string]: boolean } }> = ({ item }) => {
  if (!item) {
    return null;
  }
  const [key] = Object.keys(item);
  const icon = item[key] ? 'check-mark-blue-round' : 'x-mark-gray-round';
  return (
    <li className="flex mb-s">
      <Image name={icon} className="mr-s w-s shrink-0" />
      <span>{key}</span>
    </li>
  );
};

export default PricingSection;
